import React, { useEffect, useRef, useState }  from 'react';
import styles from './song-modal.module.scss';
import { SongType } from '../song/song';
import close from './../../assets/icons/close.svg';
import apple from './../../assets/icons/apple.svg'
import spotify from './../../assets/icons/spotify.svg';
import tidal from './../../assets/icons/tidal.svg';
import amazon from './../../assets/icons/amazon.svg';

export type Props = {
    isOpen?: boolean;
    context: string;
    song?: SongType;
    lyrics?: string;
    songLink?: string;
    updateContext: (ctx: string) => void;
    closeModal: (close: boolean) => void;
    links?: {
        amazon?: string,
        apple?: string,
        spotify?: string,
        tidal?: string,
        shop?: string
    },
    fullCredits?: string[];
}

const SongModal = ({isOpen, context, links, songLink, updateContext, closeModal, song, lyrics, fullCredits}: Props) => {
    const [ctx, setCtx] = useState('credits');
    const lyricsRef = useRef<HTMLElement | any>();

    useEffect(() => {
        if (context === 'lyrics' || (!context && lyrics)) {
            setCtx('lyrics');
        } else {
            setCtx('credits');
        }
    }, [context, lyrics]);

    const toggleTabs = (selectedTab: string) => {
        setCtx(selectedTab)
        updateContext(selectedTab)
    }

   return (
    <>
        <div className={styles['modal-wrapper']} data-modal-opened={isOpen}>
            {!fullCredits && (
                 <div className={styles['container']}>
                 <div className={styles['header-container']}>
                     <div className={styles['content-tab-buttons']}>
                         <div className={styles['link-icons']}>
                             <a className={styles['app-link']} href={links?.apple} aria-label='Apple Music Link' rel="noreferrer" target="_blank"> 
                                 <img className={styles['link-logo']} src={apple} alt="Apple Music" />
                             </a>
                             <a className={styles['app-link']} href={links?.spotify} aria-label='Spotify Link' rel="noreferrer" target="_blank"> 
                                 <img className={styles['link-logo']} src={spotify} alt="Spotify" />
                             </a>
                             <a className={styles['app-link']} href={links?.amazon} aria-label='Amazon Music Link' rel="noreferrer" target="_blank"> 
                                 <img className={styles['link-logo']} src={amazon} alt="Amazon Music" />
                             </a>
                             <a className={styles['app-link']} href={links?.tidal} aria-label='Tidal Link' rel="noreferrer" target="_blank"> 
                                 <img className={styles['link-logo']} src={tidal} alt="Tidal" />
                             </a>
                         </div>
                         <span className={styles['buttons']}>
                             {lyrics && (
                                 <>
                                     <p data-is-active={ctx === 'lyrics'} onClick={() => toggleTabs("lyrics")}>Lyrics</p>
                                     <p> / </p>
                                 </>
                             )}
                             <p data-is-active={ctx === 'credits'} onClick={() => toggleTabs("credits")}>Credits</p>
                         </span>
                         <div className={styles['song-header']}>
                         <h3><a href={songLink} aria-label={song?.title + ' Link'} rel="noreferrer"  target="_blank">{song?.title}</a></h3>
                     </div>
                     </div>
                 </div>  
                 <div className={styles['content-tabs']}>
                     <div ref={lyricsRef} className={styles['lyrics-container']} data-display-tab={ctx === 'lyrics' ? true : false}>
                        <pre>{lyrics}</pre> 
                     </div>
                     <div className={styles['credits-container']} data-display-tab={ctx === 'credits' ? true : false}>
                         <p>
                             {song?.credits}
                         </p>
                         
                     </div>
                 </div>
 
                 <div className={styles['modal-footer']}>
                      <img 
                         id="close-button"
                         onClick={() => closeModal(true)}
                         className={styles['close-logo']} 
                         src={close} 
                         
                         alt="close" />
                 </div>
             </div>
            )}
            
            {fullCredits && (
                <>
                    <div className={styles['container']} data-full-credits={true}>
                        <div className={styles['content-tabs']}>
                            <div className={styles['full-credits-container']} data-display-tab={true}>
                                {fullCredits.map((c, i) => (
                                    <p data-song-title={fullCredits[i-1] === ' ' && i < 200} data-crew={i >= 200} data-title={i === 0} data-bey={i === 1}>
                                        {c}
                                    </p>
                                ))}
                            </div>
                        </div>

                        <div className={styles['modal-footer']}>
                            <img 
                                id="close-button"
                                onClick={() => closeModal(true)}
                                className={styles['close-logo']} 
                                src={close} 
                                
                                alt="close" />
                        </div>
                    </div>
                </>
            )}
        </div>
    </>
   ) 
}

export default SongModal;