import WithSubnavigation from './navigation'; // Ensures the desktop navigation is imported
import { useState } from 'react'; // Manages the state of the modal (open/close)
import { Flex, IconButton, Box, Text, Stack } from '@chakra-ui/react'; // Chakra UI components
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'; // Icons for hamburger and close
import { Link, useLocation } from 'react-router-dom'; // Use react-router-dom for navigation and location
import styles from './header.module.scss'; // Custom styles

interface HeaderProps {
  modalOpened: boolean;
}

const Header: React.FC<HeaderProps> = ({ modalOpened }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the modal open/close
  const location = useLocation(); // Use useLocation to get the current path

  const toggleMenu = () => setIsOpen(!isOpen); // Toggles the state of the modal

  return (
    <div className={styles['header-container']} id={'header'}>
      {/* Desktop Navigation */}
      <Flex
        justifyContent="center"
        alignItems="center"
        display={{ base: 'none', md: 'flex' }} // Visible only on medium and larger screens
      >
        <WithSubnavigation darkmode={location.pathname === '/' ? true : false} /> {/* Passes dark mode setting */}
      </Flex>

      {/* Mobile Navigation - Hamburger Menu */}
      <Flex
        justifyContent="flex-end" // Aligns the hamburger to the right
        alignItems="center"
        px="1rem"
        py="0.5rem"
        display={{ base: 'flex', md: 'none' }} // Visible only on smaller screens
      >
        <IconButton
          icon={isOpen ? <CloseIcon boxSize="1.5em" /> : <HamburgerIcon boxSize="1.5em" />} // 1.5em makes it 50% larger
          variant="unstyled"
          aria-label="Menu"
          onClick={toggleMenu}
          className={styles['hamburger-icon']} // Custom style class
        />

      </Flex>

      {/* Modal that appears when the hamburger is clicked */}
      {isOpen && ( // Conditional rendering ensures the modal and close icon appear only once
        <Box
          position="fixed"
          top="0"
          right="0" // Aligns the modal to the right
          width="75vw"
          height="100vh"
          bg="black"
          color="white"
          zIndex="11" // Ensures it overlays other content
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p="2rem"
        >
          {/* Main navigation links centered */}
          <Stack spacing="1.5rem" align="center" mt="4rem">
            <Link to="https://beyonce.com">
              <Text className={styles['menu-item']}>HOME</Text>
            </Link>
            <Link to="https://shop.beyonce.com">
              <Text className={styles['menu-item']}>SHOP</Text>
            </Link>
            <Link to="https://parfum.beyonce.com">
              <Text className={styles['menu-item']}>PARFUM</Text>
            </Link>
            <Link to="https://cecred.com">
              <Text className={styles['menu-item']}>CÉCRED</Text>
            </Link>
            <Link to="https://www.sirdavis.com/?utm_source=msb&utm_medium=community&utm_campaign=SIRDAVIS_WS_USA_SIRDAVISBRLAUNCH_WHI_MASTERBRAND_SIRDAVIS_AW">
              <Text className={styles['menu-item']}>SIRDAVIS</Text>
            </Link>
          </Stack>

          {/* Footer navigation moved to modal, centered in a single line */}
          <Flex
            justifyContent="center"
            alignItems="center"
            mt="auto"
            w="100%"
            mb="2rem" // Adjusts vertical spacing from bottom
          >
            <Link to="https://beyonce.attn.tv/p/Q9J/landing-page">
              <Text className={styles['footer-item']}>JOIN</Text>
            </Link>
            <Link to="https://beencountry.com">
              <Text className={styles['footer-item']}>BEEN COUNTRY</Text>
            </Link>
            <Link to="https://beyonce.com/terms-conditions">
              <Text className={styles['footer-item']}>TERMS</Text>
            </Link>
            <Link to="https://beyonce.com/privacy-policy">
              <Text className={styles['footer-item']}>PRIVACY</Text>
            </Link>
          </Flex>
        </Box>
      )}
    </div>
  );
};

export default Header;