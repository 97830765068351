import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const fixedContent = defineStyle({
  zIndex: '200',
  position: 'fixed'
})

const backgroundImg = defineStyle({
  zIndex: '1',
  width: '100%',
  height: 'auto',
  verticalAlign: 'middle'
})

export const dividerTheme = defineStyleConfig({
  variants: {
    fixedContent,
    backgroundImg
  },
})