import { Flex, Text, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Use react-router-dom for navigation in CRA
import styles from './footer.module.scss';

export default function Footer({ darkmode = true }: { darkmode: boolean }) {
  return (
    <div>
      {/* Desktop version */}
      <Flex
        fontSize={{ base: '16px', md: '11px' }}
        color={darkmode ? 'white' : 'black'}
        bg={''}
        position='fixed'
        width='100%'
        direction={['row', 'row']}
        bottom='1.5rem'
        justify='space-between'
        align='flex-end'
        display={{ base: 'none', md: 'flex' }}
        className={styles['dekstop-footer']}
        id={'desktop-footer'}
      >
        <a href="https://beyonce.attn.tv/p/Q9J/landing-page">
          <Text px='1.5rem'>
            JOIN
          </Text>
        </a>

        <Text alignSelf='center' pl='4.5rem'>
          &copy; 2024 BEYONCÉ
        </Text>
        <Flex direction='row' justifyContent='flex-end'>
          <a href="https://beencountry.com">
            <Text pl='1.5rem'>
              BEEN COUNTRY
            </Text>
          </a>
          <Link to="https://beyonce.com/terms-conditions">
            <Text pl='1.5rem'>
              TERMS
            </Text>
          </Link>
          <Link to="https://beyonce.com/privacy-policy">
            <Text px='1.5rem'>
              PRIVACY
            </Text>
          </Link>
        </Flex>
      </Flex>

      {/* Mobile version */}
      <Flex
        fontSize={{ base: '16px', md: '11px' }}
        color={darkmode ? 'white' : 'black'}
        bg={''}
        position='fixed'
        width='100%'
        bottom='-2'
        direction={['row', 'row']}
        justify='space-around'
        align='flex-end'
        p={{ base: '1.25rem 3rem 0', md: '1.5rem' }}
        display={{ base: 'flex', md: 'none' }}
        id={'mobile-footer'}
        className={styles['mobile-footer']}
      >
        {/* No mobile hamburger menu, keeping this minimal */}
      </Flex>

      {/* Modal that appears when the hamburger is clicked */}
      <Box
        position="fixed"
        top="0"
        left="0"
        width="75vw"
        height="100vh"
        bg="black"
        color="white"
        zIndex="10"
        display="none" // No need to track 'isOpen' since it's not being toggled
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <a href="https://beyonce.attn.tv/p/Q9J/landing-page">
          <Text p='1.5rem' textAlign="center">
            JOIN
          </Text>
        </a>
        <a href="https://beencountry.com">
          <Text p='1.5rem' textAlign="center">
            BEEN COUNTRY
          </Text>
        </a>
        <Link to="https://beyonce.com/terms-conditions">
          <Text p='1.5rem' textAlign="center">
            TERMS
          </Text>
        </Link>
        <Link to="https://beyonce.com/privacy-policy">
          <Text p='1.5rem' textAlign="center">
            PRIVACY
          </Text>
        </Link>
      </Box>
    </div>
  );
}