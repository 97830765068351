import React, { useState } from 'react';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router } from 'react-router-dom';
import { dividerTheme } from './components/custom-chakra/styles'; // Use named import
import styles from './App.module.scss';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import MusicContent from './components/music-content/music-content';
import CleanMusicContent from './components/music-content/clean-music-content';
import SongModal from './components/song-modal/song-modal';
import { fullCredits } from './components/music-content/credits.model';

// Extend the theme with your custom configurations
const customTheme = extendTheme({
  styles: {
    global: {
      '@font-face': {
        fontFamily: "BB Manual Mono Pro TX",
        src: 'url("./assets/fonts/BBManualMonoProTX-Regular.ttf")',
        fontDisplay: 'auto',
        fontStyle: 'normal',
        fontStretch: 'normal',
        fontWeight: 400,
      },
      'html, body, #root': {
        backgroundColor: 'black', // Set the background color to black
        color: 'white', // Set the text color to white
        fontFamily: '"BB Manual Mono Pro TX", sans-serif', // Apply the custom font
      },
    },
  },
  components: {
    Divider: dividerTheme,  // Add your custom Divider theme here
  },
});

function App() {
  const [currVersion, setCurrVersion] = useState('clean');
  const [modalState, setModalState] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const setCurrentVersion = (vers: string) => {
    setCurrVersion(vers);
  }

  const gettingModalState = (currState: boolean) => {
    setModalState(currState);
  }

  const openCreditsFunc = () => {
    setOpenCredits(true);
  }

  const closeCredits = () => {
    setOpenCredits(!openCredits);
  }

  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <div className={styles['App']} data-modal-opened={modalState}>
          {/* Pass modalState to Header */}
          <Header modalOpened={modalState} />
          {/* Pass a darkmode prop to Footer */}
          <Footer darkmode={true} />
          <div className={styles['version-select']} data-modal-opened={modalState}>
            <p data-is-active={currVersion === 'clean'} onClick={() => setCurrentVersion('clean')}>Clean</p>
            <p>/</p>
            <p data-is-active={currVersion === 'explicit'} onClick={() => setCurrentVersion('explicit')}>Explicit</p>
          </div>
          {!modalState && (
            <div className={styles['full-credits-button']}>
              <p onClick={() => openCreditsFunc()}>Credits</p>
            </div>
          )}

          {currVersion === 'clean' && (
            <CleanMusicContent modalState={gettingModalState} />
          )}

          {currVersion === 'explicit' && (
            <MusicContent modalState={gettingModalState} />
          )}

          {openCredits && (
            <SongModal
              isOpen={openCredits}
              context={'full-credits'}
              updateContext={closeCredits}
              closeModal={closeCredits}
              fullCredits={fullCredits.credits}
            />
          )}
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;