import styles from './song.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import SongModal from '../song-modal/song-modal';
import apple from './../../assets/icons/apple.svg'
import spotify from './../../assets/icons/spotify.svg';
import tidal from './../../assets/icons/tidal.svg';
import amazon from './../../assets/icons/amazon.svg';
import download from './../../assets/icons/download.svg';

export type SongType = {
    title: string,
    lyrics: string,
    explicitLyrics: string;
    credits: string,
    links: {
        amazon?: string,
        apple?: string,
        spotify?: string,
        tidal?: string,
        shop?: string,
    },
    explicitLinks: {
        amazon?: string,
        apple?: string,
        spotify?: string,
        tidal?: string,
        shop?: string,
    },
    isExpanded: boolean
}

export type Props = {
    song: SongType;
    i: number;
    isExpanded?: boolean;
    lyrics: string;
    links:{
        amazon?: string;
        apple?: string;
        spotify?: string;
        tidal?: string;
        shop?: string;
    };
    link: string;
    toggleExpansion: () => void;
    modalOpened: (isOpen: boolean) => void;
}

const Song = ({song, i, isExpanded, lyrics, links, link, modalOpened, toggleExpansion}: Props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalContext, setModalContext] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const linksRef = useRef<HTMLElement | any>();

    const openCreditsModal = () => {
        setModalContext('credits');
        setOpenModal(true);
        modalOpened(true);
    }

    const openLyricsModal = () => {
        setModalContext('lyrics')
        setOpenModal(true);
        modalOpened(true);
    }

    const setContext = (context: string) => {
        setModalContext('credits');
    }

    const closeModal = (close: boolean) => {
        setOpenModal(false)
        modalOpened(false);
    }

    useEffect(() => {
        const centerIcons = () => {
            const width = window.innerWidth;
            const remToPixels = 19.5 * 16;
            const center = (width - remToPixels) / 2;
            
            if (linksRef.current) {
                if (linksRef.current && window.innerWidth > 1023) {
                    linksRef.current.style.position = 'relative';
                    linksRef.current.style.left =  center + 'px';
                } else {
                    linksRef.current.style.position = 'relative';
                    linksRef.current.style.left =  0 + 'px';
                }
            }
        }

        const checkMobile = () => {
            const check = window.innerWidth > 1023 ? false : true;
            setIsMobile(check)
        }

        checkMobile();
        centerIcons();

        window.addEventListener("resize", () => {
            checkMobile();
            centerIcons();
        });
        
    })

    return (
        <>
            <div className={styles['song-wrapper']} data-is-expanded={isExpanded} onClick={() => toggleExpansion()}>
                <div className={styles['container']}>
                    <div className={styles['track-number']}>
                        <span className={styles['fixed-track-number']}>{i + 1 + '.'}</span>
                        {isExpanded && song.links &&  (
                            <div className={styles['logo-list']} ref={linksRef}>
                                <div className={styles['dsp-links']}>
                                    {links.apple && (
                                        <a className={styles['app-link']} href={links.apple} aria-label='Apple Music Link' rel="noreferrer" target="_blank"> 
                                         <img className={styles['link-logo']} src={apple} alt="Apple Music" />
                                        </a>
                                    )}
                                    {links.spotify && (
                                        <a className={styles['app-link']} href={links.spotify} aria-label='Spotify Link' rel="noreferrer" target="_blank"> 
                                            <img className={styles['link-logo']} src={spotify} alt="Spotify" />
                                        </a>
                                    )}
                                    {links.amazon && (
                                        <a className={styles['app-link']} href={links.amazon} aria-label='Amazon Music Link' rel="noreferrer" target="_blank"> 
                                            <img className={styles['link-logo']} src={amazon} alt="Amazon Music" />
                                        </a>
                                    )}
                                    {links.tidal && (
                                        <a className={styles['app-link']} href={links.tidal} aria-label='Tidal Link' rel="noreferrer" target="_blank"> 
                                            <img className={styles['link-logo']} src={tidal} alt="Tidal" />
                                        </a>
                                    )}
                                    {links.shop && (
                                        <a className={styles['app-link']} href={links.shop} aria-label='Shop Link' rel="noreferrer" target="_blank"> 
                                            <img className={styles['link-logo']} src={download} alt="shop.Beyonce.com" />
                                        </a>
                                    )}
                                </div>
                            {!isMobile && (
                                <div className={styles['lyrics-and-credits-buttons']}>
                                    {lyrics && (
                                        <>
                                            <p data-hide-this={true} className={styles["lyrics-button"]} onClick={() => openLyricsModal()}>
                                                Lyrics
                                            </p>
                                            <p data-hide-this={true}> / </p>
                                        </>
                                    )}
                                    
                                    <p className={styles["credits-button"]} onClick={() => openCreditsModal()}>
                                        Credits
                                    </p>
                                </div> 
                            )}   
                            </div>  
                        )}

                    </div>
                    <div className={styles['track-title']}>
                        {song.title}
                        {isExpanded && isMobile && (
                            <div className={styles['lyrics-and-credits-buttons']}> 
                            {lyrics && (
                                <>
                                    <p className={styles["lyrics-button"]} onClick={() => openLyricsModal()}>
                                        Lyrics
                                    </p>
                                    <p> / </p>
                                </>
                            )}
                                
                                <p className={styles["credits-button"]} onClick={() => openCreditsModal()}>
                                    Credits
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <SongModal 
                isOpen={openModal} 
                context={modalContext} 
                updateContext={setContext} 
                closeModal={closeModal} 
                song={song}
                songLink={link}
                links={links}
                lyrics={lyrics}
            />
        </>
    )
}

export default Song;