import styles from './music-content.module.scss';
// eslint-disable-next-line
import React, { useState } from 'react';
import Song, { SongType }  from '../song/song';
import { songsData } from './songs.model';

export type Props = {
    songs?: SongType[];
    modalState: (currState: boolean) => void;
}

const CleanMusicContent = ({songs = songsData, modalState}: Props) => {
    const [tracks, setTracks] = useState(songs);
    const [currModal, setCurrModal] = useState(false);

    const toggleSong = (i: number) => {
        //set all false;
        const temp = [...songs];
        temp.forEach((s) => {
            s.isExpanded = false;
        });
        // set selected true
        temp[i].isExpanded = true;
        setTracks(temp);
    }

    const gettingModalState = (modal: boolean) => {
        modalState(modal);
        setCurrModal(modal)
    }


    return (
        <>
            <div className={styles['content']} data-modal-opened={currModal}>
                {tracks && tracks.map((s, i) => 
                    (
                       <Song 
                            song={s} 
                            i={i} 
                            key={i}
                            links={s.links} 
                            lyrics={s.lyrics}
                            isExpanded={s.isExpanded} 
                            toggleExpansion={() => toggleSong(i)}
                            link={"https://beyonce.lnk.to/COWBOYCARTERCLEAN"}
                            modalOpened={gettingModalState} 
                        />
                    )
                )}
            </div>
        </>
    )
}

export default CleanMusicContent;