import { Flex, Text, Stack } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import styles from './header.module.scss';

export default function WithSubnavigation({ darkmode = true }: { darkmode: boolean }) {
  return (
    <Flex color={darkmode ? 'white' : 'black'} justifyContent='center'>
      <DesktopNav />
    </Flex>
  );
}

const DesktopNav = () => {
  const location = useLocation();
  return (
    <Flex 
      className={styles['header']} 
      direction={{ base: 'row', md: 'row' }} 
      w={{ base: '100vw', md: 'auto' }} // Allow the width to auto-adjust based on content
      justify='center' 
      alignItems='center' 
      px={{ base: '2rem' }}
    >
      {NAV_ITEMS.map((navItem) => location.pathname !== navItem.href ? (
        <Stack 
          className={'nav-item'} 
          key={navItem.href} 
          direction={'row'} 
          p={{ base: '1rem', md: '1.5rem' }} 
          display={navItem.hidden.includes(location.pathname) ? "none" : "inline"}
        >
          <Link className={'nav-link'} to={navItem.href}>
            <Text 
              className={'nav-text'} 
              fontWeight='400' 
              lineHeight='1.5rem' 
              fontSize='20px' 
              textTransform="uppercase"
            >
              {navItem.label}
            </Text>
          </Link>
        </Stack>
      ) : null)}
    </Flex>
  );
};

interface NavItem {
  label: string;
  href: string;
  hidden: string[];
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'HOME',
    href: 'https://beyonce.com/',
    hidden: [],
  },
  {
    label: 'SHOP',
    href: 'https://shop.beyonce.com',
    hidden: ['/privacy-policy', '/terms-conditions'],
  },
  {
    label: 'MUSIC',
    href: 'https://music.beyonce.com/',
    hidden: ['/'],
  },
  {
    label: 'PARFUM',
    href: 'https://parfum.beyonce.com/',
    hidden: [],
  },
  {
    label: 'CÉCRED',
    href: 'https://cecred.com/',
    hidden: [],
  },
  {
    label: 'SIRDAVIS',
    href: 'https://www.sirdavis.com/?utm_source=msb&utm_medium=community&utm_campaign=SIRDAVIS_WS_USA_SIRDAVISBRLAUNCH_WHI_MASTERBRAND_SIRDAVIS_AW',
    hidden: [],
  },
];